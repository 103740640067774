.table-schedule .date-row {
  text-align: center;
}

.table-schedule .auditor-line td {
  padding: 0;
  overflow: hidden;
}

.table-schedule .auditor-line .btn {
  width: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-schedule .auditor-line .btn:first-child:last-child {
  width: 40px;
}

.table-schedule .auditor-line .btn-schedule {
  color: #fff;
  background: #337ab7;
}

.table-schedule .auditor-line .btn-selected {
  background: #4ba84b;
  color: #fff;
}

.table-schedule .fixed-left {
  background: #f5f5f5;
  border-left: 1px solid #ddd;
  height: 32px;
  left: 15px;
  overflow: hidden;
  overflow-y: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}
.table-schedule .fixed-left.match {
  border-left: 5px solid #4ba84b;
}
.table-schedule .fixed-left.expert {
  border-left: 10px solid #4ba84b;
}
.table-schedule .fixed-left + td,
.table-schedule .fixed-left + th {
  padding-left: 140px;
}
