body {
  background: #fff !important;
}

.logo {
  text-align: center;
}
.logo img {
  height: 40px;
  width: auto;
}

@media (min-width: 992px) {
  .page-auth.page-login {
    display: flex;
  }
  .page-auth.page-login .unsplash-image {
    flex: 1;
    height: 100vh;
    background: midnightblue url("../img/background/Iso_Map.jpg") center center;
    background-size: cover;
  }
  .page-auth.page-login .unsplash-image.has-preview {
    background: none;
  }
  .page-auth.page-login .unsplash-image.has-preview iframe {
    height: 100vh;
    width: 100%;
  }
  .page-auth.page-login .inner-page {
    min-width: 400px;
  }
  .page-auth.page-login .center-block {
    width: 300px;
    margin: 0 auto;
  }
}
