.tuv.content-wrapper{
  padding-bottom: 100px;
}

.mb-0{
	margin-bottom: 0 !important;
}

.text-grey{
	color: #c6c6c6;
}

.advance-menu-hover:hover {
	background-color: #1a5dc6 !important
}